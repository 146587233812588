import { LocalStoreService } from './local-store.service';
import { LOCAL_STORE_AUTH } from '../constants/store';

export class AuthService {
  static getAuthorizationHeader() {
    const auth = LocalStoreService.getItem(LOCAL_STORE_AUTH);
    if (!auth) return null;
    return 'Basic ' + Buffer.from(`${auth.username}:${auth.password}`, 'utf8').toString('base64');
  }

  static setCredentials(username: string, password: string) {
    LocalStoreService.setItem(LOCAL_STORE_AUTH, {
      username,
      password
    });
  }

  static logout() {
    LocalStoreService.setItem(LOCAL_STORE_AUTH, null);
  }
}
