import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import ReactGA from 'react-ga';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Page from './components/Page/Page';
import { history } from './helpers/history';
import store from './store';
import AdminQuizQuestions from './views/AdminQuizQuestions/AdminQuizQuestions';
import AdminQuizzes from './views/AdminQuizzes/AdminQuizzes';
import EndQuiz from './views/EndQuiz/EndQuiz';
import Home from './views/Home/Home';
import Login from './views/Login/Login';
import MainQuiz from './views/MainQuiz/MainQuiz';
import Quiz from './views/Quiz/Quiz';
import QuizStart from './views/QuizStart/QuizStart';

ReactGA.initialize('UA-88135025-4');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LoadingBar className='loading' />
        <ToastContainer autoClose={5000} />
        <Header></Header>
        <Switch>
          <Page path='/main/end' component={EndQuiz} />
          <Page path='/main/:questionUuid' component={MainQuiz} />
          <Page path='/admin/quiz/:quizUuid/questions' component={AdminQuizQuestions} />
          <Page path='/admin/quiz' component={AdminQuizzes} />
          <Page path='/quiz/:quizUuid/start' component={QuizStart} />
          <Page path='/quiz/:quizUuid' component={Quiz} />
          <Page path='/login' component={Login} />
          <Page path='/' component={Home} />
        </Switch>
        <Footer></Footer>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
