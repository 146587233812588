import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { AppThunk } from '..';
import { Quiz } from '../../models/quiz.model';
import QuizService from '../../services/quiz.service';
import {
  deleteQuizSuccess, editQuizFailure, editQuizLoading, editQuizSuccess, getAdminQuizSuccess, getQuizzesSuccess, setSelectedQuiz, AdminEditQuizView, setQuizView
} from './adminQuizzes.slice';


export const selectQuiz = (quiz: Quiz) => setSelectedQuiz(quiz);

export const deselectQuiz = () => setSelectedQuiz(null);

export const setQuizViewAction = (view: AdminEditQuizView) => setQuizView(view);

export const fetchQuizzes = (): AppThunk => async dispatch => {
  try {
    dispatch(showLoading());
    const quizzes = await QuizService.getQuizzes();
    dispatch(getQuizzesSuccess(quizzes));
  } finally {
    dispatch(hideLoading());
  }
};


export const createQuiz = (quiz: Partial<Quiz>, file?: File): AppThunk => async dispatch => {
  try {
    dispatch(editQuizLoading());
    const createdQuiz = await QuizService.createQuiz(quiz);
    if (file) {
      await QuizService.uploadQuizImage(createdQuiz.uuid, file);
    }
    dispatch(getAdminQuizSuccess(createdQuiz));
    dispatch(editQuizSuccess());
    dispatch(setSelectedQuiz(null));
  } catch (e) {
    dispatch(editQuizFailure());
  }
};

export const updateQuiz = (quiz: Quiz, file?: File): AppThunk => async dispatch => {
  try {
    dispatch(editQuizLoading());
    await QuizService.updateQuiz(quiz);
    const updatedQuiz = await QuizService.getQuiz(quiz.uuid);
    if (file) {
      await QuizService.uploadQuizImage(quiz.uuid, file);
    }
    dispatch(getAdminQuizSuccess(updatedQuiz));
    dispatch(editQuizSuccess());
    dispatch(setSelectedQuiz(null));
  } catch (e) {
    dispatch(editQuizFailure());
  }
};

export const deleteQuiz = (quizUuid: string): AppThunk => async dispatch => {
  try {
    dispatch(editQuizLoading());
    await QuizService.deleteQuiz(quizUuid);
    dispatch(deleteQuizSuccess(quizUuid));
    dispatch(editQuizSuccess());
    dispatch(setSelectedQuiz(null));
  } catch (e) {
    dispatch(editQuizFailure());
  }
};
