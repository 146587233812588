import { Form as FormikForm, Formik } from 'formik';
import React, { Component } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Loader } from '../../components/Loader/Loader';
import { getApiErrorMessage } from '../../helpers/error-message';
import { history } from '../../helpers/history';
import { AuthService } from '../../services/auth.service';
import QuizService from '../../services/quiz.service';

interface LoginForm {
  username: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .required('Vous devez entrer votre nom d\'utilisateur.'),
  password: Yup.string().required('Vous devez entrer votre mot de passe.')
});

export interface LoginProps {

}

export interface LoginState {
  loading: boolean;
}

export default class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      loading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(form: LoginForm) {
    this.setState({ loading: true });
    AuthService.setCredentials(form.username, form.password);
    QuizService.getQuizzes()
      .then(rep => {
        history.push('/admin/quiz');
      })
      .catch(e => {
        toast.error(getApiErrorMessage(e));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading } = this.state;

    return (
      <div className="container">
        <div className='row justify-content-center'>
          <Col xs={12} md={6}>
            <h1 className="mt-4">Connexion</h1>
            <div className="mt-4">
              Administration BrainQuiz
            </div>
            <hr />
            <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              validationSchema={LoginSchema}
              onSubmit={this.onSubmit}
            >
              {({
                values, errors, touched, handleChange, handleBlur
              }) => (
                <FormikForm>
                  <Form.Group>
                    <Form.Label>Nom d&apos;utilisateur</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="Nom d'utilisateur"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      formNoValidate
                      isInvalid={!!(errors.username && touched.username)}
                    ></Form.Control>
                    {errors.username && touched.username ? (
                      <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Entrez votre mot de passe"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      formNoValidate
                      isInvalid={!!(errors.password && touched.password)}
                    ></Form.Control>
                    {errors.password && touched.password ? (
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                  <hr/>
                  <div className="mt-4 d-flex align-items-center">
                    <Button type="submit" disabled={loading}>Se connecter</Button>
                    {loading && <Loader />}
                  </div>
                </FormikForm>
              )}
            </Formik>
          </Col>
        </div>
      </div>
    );
  }
}
