import React, { Component } from 'react';
import {
  Button, Col, Container, Row
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis
} from 'recharts';
import { bindActionCreators, Dispatch } from 'redux';
import SlidingPanel, { PanelType } from '../../components/SlidingPanel/SlidingPanel';
import { deselectQuestion, fetchQuestions, selectQuestion } from '../../store/adminQuestions/adminQuestions.actions';
import { fetchQuiz } from '../../store/quiz/quiz.actions';
import { RootState } from '../../store/rootReducer';
import EditQuestion from './EditQuestion/EditQuestion';
import QuestionItem from './QuestionItem/QuestionItem';
import QuizRank from './QuizRank/QuizRank';


const mapStateToProps = ({ quiz, adminQuestions }: RootState) => {
  // Chart - sessions per question
  const chartData = [];

  const { selectedQuestion, questions, quizStats } = adminQuestions;

  if (questions && quizStats) {
    for (const question of questions) {
      if (quizStats.stats[question.uuid]) {
        chartData.push({
          name: question.order,
          sessions: quizStats.stats[question.uuid].sessions.length
        });
      }
    }
  }

  return {
    quiz: quiz.quiz,
    questions,
    quizStats,
    selectedQuestion,
    chartData,
    rank: quizStats?.rank ?? []
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchQuiz,
  fetchQuestions,
  selectQuestion,
  deselectQuestion,
}, dispatch);

interface AdminQuizQuestionsRouteParams {
  quizUuid: string;
}

export interface AdminQuizQuestionsProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  RouteComponentProps<AdminQuizQuestionsRouteParams> {

}

class AdminQuizQuestions extends Component<AdminQuizQuestionsProps> {
  constructor(props: AdminQuizQuestionsProps) {
    super(props);

    this.addQuestion = this.addQuestion.bind(this);
    this.reloadQuestions = this.reloadQuestions.bind(this);
  }

  componentDidMount() {
    const { quizUuid } = this.props.match.params;
    this.props.fetchQuiz(quizUuid);
    this.props.fetchQuestions(quizUuid);
  }

  addQuestion() {
    if (!this.props.quiz) return;

    this.props.selectQuestion({
      uuid: '',
      name: '',
      description: '',
      order: 0,
      answers: [],
      quiz: this.props.quiz.uuid,
      deletedAt: null,
    });
  }

  reloadQuestions() {
    const { quizUuid } = this.props.match.params;
    this.props.fetchQuestions(quizUuid);
  }

  render() {
    const {
      questions, selectedQuestion, quiz, chartData
    } = this.props;

    if (!quiz) return <div></div>;

    return (
      <div className="content">
        <SlidingPanel
          type={PanelType.Right}
          isOpen={selectedQuestion !== null}
          size={50}
          backdropClicked={() => { this.props.deselectQuestion(); }}
        >
          <div>
            <EditQuestion question={selectedQuestion!}></EditQuestion>
          </div>
        </SlidingPanel>
        <Container fluid={true}>
          <Row>
            <Col xs={12} className="d-flex mb-4 align-items-center">
              <h3 className="flex-grow-1">{quiz.name}</h3>
              <div>
                <Button onClick={this.reloadQuestions}><i className="far fa-sync"></i></Button>
                {' '}<Button onClick={this.addQuestion}><i className="far fa-plus"></i> Ajouter une question</Button>
              </div>
            </Col>
            <Col md={8} xs={12}>
              {questions.map(question => (
                <div
                  key={question.uuid}
                  className="mb-2">
                  <QuestionItem
                    question={question}></QuestionItem>
                </div>
              ))}
            </Col>
            <Col md={4} xs={12}>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={chartData}>
                  <XAxis dataKey="name" />
                  <YAxis width={30} />
                  <Legend />
                  <Bar dataKey="sessions" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
              <QuizRank rank={this.props.rank} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminQuizQuestions);
