import moment from 'moment';
import React, { Component } from 'react';
import { formatDuration } from '../../../helpers/format-duration';
import { UserRank } from '../../../models/question.model';


export interface QuizRankProps {
  rank: UserRank[];
}

export default class QuizRank extends Component<QuizRankProps> {
  render() {
    return (
      <div className="card-content">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Pseudo</th>
              <th>Question (ou temps)</th>
            </tr>
          </thead>
          <tbody>
            {this.props.rank.map((rank, index) => (<tr key={rank.uuid}>
              <td>{index + 1}</td>
              <td>{rank.username}</td>
              <td>{rank.order
                ? ('Question ' + rank.order)
                : formatDuration(
                  moment.duration(
                    moment(rank.lastCheck).diff(rank.createdAt)
                  ).asSeconds()
                )}</td>
            </tr>))}
          </tbody>
        </table>
      </div>
    );
  }
}
