import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AnswerMap } from '../../../models/question.model';

export interface QuestionAnswerListProps {
  answerMaps: AnswerMap[];
}

class QuestionAnswerList extends Component<QuestionAnswerListProps> {
  render() {
    const { answerMaps } = this.props;

    return (
      <ul style={{ fontWeight: 300 }}>
        {answerMaps.map(answer => (
          <li key={answer.answer}>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip-${answer.answer}`}>
                  {Array.from(new Set(answer.sessions.map(session => session.username))).join(', ')}
                </Tooltip>
              }
            >
              <span>
                {answer.answer} : {answer.sessions.length} réponse{answer.sessions.length > 1 ? 's' : ''}
                <i className="far fa-question-circle" style={{ color: 'grey', fontSize: '12px', padding: '0.5em' }}></i>
              </span>
            </OverlayTrigger>{' '}
          </li>
        ))}
      </ul>
    );
  }
}

export default QuestionAnswerList;
