import moment from 'moment';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toast } from 'react-toastify';
import { AppThunk } from '..';
import { getApiErrorMessage } from '../../helpers/error-message';
import { history } from '../../helpers/history';
import { Session } from '../../models/session.model';
import SessionService from '../../services/session.service';
import {
  setCheckAnswerLoading, setCheckAnswerSuccess, setEndPageFailed, setEndPagePending, setEndPageSuccess, setLastAttempt, setNextAllowedTry, setSession, setSessionQuestion, setSessionQuestionFailed, setSessionQuestionLoading
} from './session.slice';

export const redirectToQuestion = (session: Session): AppThunk => async dispatch => {
  // Load current question into storage
  SessionService.saveLastSessionToStorage(session);
  // Load session data (next question)
  const sessionData = await SessionService.getSession(session.uuid);
  if (sessionData.hasFinishedQuiz) {
    history.push('/main/end');
  } else {
    history.push('/main/' + sessionData.questionUuid);
  }
};

/** Load a session from local storage */
export const loadSessionFromStorage = (quizUuid: string): AppThunk => dispatch => {
  const session = SessionService.loadSessionFromStorage(quizUuid);
  dispatch(setSession(session));
};

/** Load the last session from local storage */
export const loadLastSessionFromStorage = (): AppThunk => dispatch => {
  const lastSession = SessionService.getLastSessionFromStorage();
  if (!lastSession) {
    throw new Error('Last session not found');
  }
  dispatch(setSession(lastSession));
};


/** Load the game */
export const loadGame = (): AppThunk => async dispatch => {
  try {
    dispatch(loadLastSessionFromStorage());
  } catch (e) {
    // Failed to load the question
    // Redirect to home
    toast.error('Impossible de charger la session courante.');
    history.push('/');
  }
};

export const fetchSessionQuestion = (questionUuid: string): AppThunk => async (dispatch, getState) => {
  const { session } = getState();
  if (!session.session) return;
  dispatch(showLoading());
  dispatch(setSessionQuestionLoading());
  try {
    const question = await SessionService.getSessionQuestion(session.session.uuid, questionUuid);
    dispatch(setSessionQuestion(question));
  } catch (e) {
    dispatch(setSessionQuestionFailed());

    // Failed to load the question
    // Redirect to home
    toast.error('Impossible de charger la question.');
    history.push('/');
  } finally {
    dispatch(hideLoading());
  }
};

export const checkAnswer = (questionUuid: string, answer: string): AppThunk<Promise<boolean | null>> => async (dispatch, getState) => {
  const { session } = getState();
  if (!session.session) return null;

  dispatch(setCheckAnswerLoading());

  try {
    const check = await SessionService.checkAnswer(session.session.uuid, questionUuid, answer);
    if (check.isCorrect) {
      return true;
    }
    // Wrong answer
    // Set next allowed try
    dispatch(setLastAttempt(moment().valueOf()));
    dispatch(setNextAllowedTry(moment().add(check.cooldown, 'seconds').valueOf()));
    return false;
  } catch (e) {
    toast.error('Impossible de valider, merci de réessayer.');
  } finally {
    dispatch(setCheckAnswerSuccess());
  }
  return null;
};

export const fetchEndPage = (): AppThunk => async (dispatch, getState) => {
  const { session } = getState();
  if (!session.session) return;
  try {
    dispatch(setEndPagePending());
    const endPage = await SessionService.getEndPage(session.session.uuid);
    dispatch(setEndPageSuccess(endPage));
  } catch (e) {
    toast.error(getApiErrorMessage(e));
    dispatch(setEndPageFailed());
  }
};
