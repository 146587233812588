import { fetchApi } from './api.service';
import Config from '../constants/config';
import { Question } from '../models/question.model';

export default class QuestionService {
  static getQuestions(quizUuid: string) {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/questions',
    });
  }

  static getQuizStats(quizUuid: string) {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/questions/stats',
    });
  }

  static getQuestion(quizUuid: string, questionUuid: string) {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/questions/' + questionUuid,
    });
  }

  static createQuestion(quizUuid: string, question: Partial<Question>) {
    return fetchApi({
      method: 'POST',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/questions',
      data: {
        name: question.name,
        description: question.description,
        answers: question.answers,
      }
    });
  }

  static updateQuestion(quizUuid: string, question: Question) {
    return fetchApi({
      method: 'PUT',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/questions/' + question.uuid,
      data: {
        name: question.name,
        description: question.description,
        answers: question.answers,
      }
    });
  }

  static deleteQuestion(quizUuid: string, questionUuid: string) {
    return fetchApi({
      method: 'DELETE',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/questions/' + questionUuid,
    });
  }

  static orderQuestion(quizUuid: string, questionUuid: string, relativeOrder: number) {
    return fetchApi({
      method: 'POST',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/questions/' + questionUuid + '/order',
      data: {
        relativeOrder
      },
    });
  }
}
