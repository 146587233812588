export type ApiErrorMessageMap = {[apiError: string]: string};

const errors: {[apiError: string]: string} = {
  Unauthorized: 'Vous n\'avez pas accès à cette page.',
  'The quiz has not started yet.': "Le quiz n'a pas encore démarré.",
  'Request failed with status code 500': 'Une erreur interne est survenue.',
};

export const getApiErrorMessage = (error: any, defaultError?: string | ApiErrorMessageMap) => {
  const message: string = error.response?.data?.message
    ?? error.response?.data?.error
    ?? error.message
    ?? (typeof defaultError === 'string' && defaultError)
    ?? 'Une erreur est survenue.';
  const allErrors = {
    ...errors,
    ...(typeof defaultError === 'object' ? defaultError : {})
  };
  if (message && allErrors[message]) {
    return allErrors[message];
  }
  return message;
};
