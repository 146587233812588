import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Dispatch } from 'redux';
import { AppThunk } from '..';
import { Question } from '../../models/question.model';
import QuestionService from '../../services/question.service';
import {
  deleteQuestionSuccess, editQuestionFailure, editQuestionLoading, editQuestionSuccess, getQuestionsSuccess, getQuestionSuccess, getQuizStatsSuccess, setSelectedQuestion
} from './adminQuestions.slice';


export const selectQuestion = (question: Question) => setSelectedQuestion(question);

export const deselectQuestion = () => setSelectedQuestion(null);


export const fetchQuestions = (quizUuid: string): AppThunk => async dispatch => {
  try {
    dispatch(getQuizStatsSuccess(null));
    dispatch(showLoading());
    QuestionService.getQuestions(quizUuid)
      .then(quiz => {
        dispatch(getQuestionsSuccess(quiz));
      });

    QuestionService.getQuizStats(quizUuid)
      .then(quizStats => {
        dispatch(getQuizStatsSuccess(quizStats));
      });
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(hideLoading());
  }
};

export const fetchQuestion = (quizUuid: string, questionUuid: string): AppThunk => async dispatch => {
  try {
    dispatch(showLoading());
    const question = await QuestionService.getQuestion(quizUuid, questionUuid);
    dispatch(getQuestionSuccess(question));
  } finally {
    dispatch(hideLoading());
  }
};

export const updateQuestion = (quizUuid: string, question: Question): AppThunk => async dispatch => {
  try {
    dispatch(editQuestionLoading());
    dispatch(showLoading());
    await QuestionService.updateQuestion(quizUuid, question);
    const updatedQuestion = await QuestionService.getQuestion(quizUuid, question.uuid);
    dispatch(getQuestionSuccess(updatedQuestion));
    dispatch(editQuestionSuccess());
    dispatch(setSelectedQuestion(null));
  } catch (e) {
    dispatch(editQuestionFailure(e.message));
  } finally {
    dispatch(hideLoading());
  }
};


export const createQuestion = (quizUuid: string, question: Partial<Question>): AppThunk => async dispatch => {
  try {
    dispatch(editQuestionLoading());
    dispatch(showLoading());
    const createdQuestion = await QuestionService.createQuestion(quizUuid, question);
    dispatch(getQuestionSuccess(createdQuestion));
    dispatch(editQuestionSuccess());
    dispatch(setSelectedQuestion(null));
  } catch (e) {
    dispatch(editQuestionFailure(e.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const deleteQuestion = (quizUuid: string, questionUuid: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(editQuestionLoading());
    dispatch(showLoading());
    await QuestionService.deleteQuestion(quizUuid, questionUuid);
    dispatch(deleteQuestionSuccess(questionUuid));
    dispatch(editQuestionSuccess());
    dispatch(setSelectedQuestion(null));
  } catch (e) {
    dispatch(editQuestionFailure(e.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const moveQuestion = (quizUuid: string, questionUuid: string, direction: number): AppThunk<Promise<void>> => async dispatch => {
  await QuestionService.orderQuestion(quizUuid, questionUuid, direction);
  const quiz = await QuestionService.getQuestions(quizUuid);
  dispatch(getQuestionsSuccess(quiz));
};
