import React, { Component } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.scss';

export default class Header extends Component {
  render() {
    return (
      <Navbar variant="dark">
        <Container>
          <Link to="/">
            <Navbar.Brand>
              <i className="fas fa-brain"></i> BrainQuiz
            </Navbar.Brand>
          </Link>
        </Container>
      </Navbar>
    );
  }
}
