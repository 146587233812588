import { fetchApi } from './api.service';
import Config from '../constants/config';
import { Session } from '../models/session.model';
import { Quiz } from '../models/quiz.model';

export default class QuizService {
  static getHomeQuizzes() {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/quiz/home',
    });
  }

  static getQuizzes() {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/quiz',
    });
  }

  static getQuiz(quizUuid: string) {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/quiz/' + quizUuid,
    });
  }

  static startQuiz(quizUuid: string, username: string): Promise<Session> {
    return fetchApi({
      method: 'POST',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/start',
      data: {
        username,
      }
    });
  }

  static createQuiz(quiz: Partial<Quiz>): Promise<Quiz> {
    return fetchApi({
      method: 'POST',
      url: Config.apiUrl + '/quiz',
      data: {
        name: quiz.name,
        subtitle: quiz.subtitle,
        description: quiz.description,
        visible: quiz.visible,
        featured: quiz.featured,
        startDate: quiz.startDate,
      }
    });
  }

  static updateQuiz(quiz: Partial<Quiz>): Promise<Quiz> {
    return fetchApi({
      method: 'PUT',
      url: Config.apiUrl + '/quiz/' + quiz.uuid,
      data: {
        name: quiz.name,
        subtitle: quiz.subtitle,
        description: quiz.description,
        visible: quiz.visible,
        featured: quiz.featured,
        startDate: quiz.startDate,
        endPages: quiz.endPages,
      }
    });
  }

  static deleteQuiz(quizUuid: string) {
    return fetchApi({
      method: 'DELETE',
      url: Config.apiUrl + '/quiz/' + quizUuid,
    });
  }

  static uploadQuizImage(quizUuid: string, file: File) {
    const data = new FormData();
    data.append('image', file);
    return fetchApi({
      method: 'PUT',
      url: Config.apiUrl + '/quiz/' + quizUuid + '/image',
      data
    });
  }
}
