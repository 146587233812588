import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { SlideDown } from 'react-slidedown';
import { bindActionCreators, Dispatch } from 'redux';
import ReactMarkdown from 'react-markdown';
import { Question } from '../../../models/question.model';
import { selectQuestion, moveQuestion } from '../../../store/adminQuestions/adminQuestions.actions';
import { RootState } from '../../../store/rootReducer';
import './QuestionItem.scss';
import { Loader } from '../../../components/Loader/Loader';
import QuestionAnswerList from '../QuestionAnswerList/QuestionAnswerList';

const mapStateToProps = ({ adminQuestions }: RootState) => {
  return {
    stats: adminQuestions.quizStats?.stats ?? null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  selectQuestion,
  moveQuestion,
}, dispatch);

export interface QuestionItemProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {
  question: Question;
}

export interface QuestionItemState {
  open: boolean;
}

class QuestionItem extends Component<QuestionItemProps, QuestionItemState> {
  constructor(props: QuestionItemProps) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggleQuestion = this.toggleQuestion.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
  }

  componentDidMount() {

  }

  toggleQuestion() {
    this.setState((prev) => ({ open: !prev.open }));
  }

  editQuestion() {
    this.props.selectQuestion(this.props.question);
  }

  getQuestionStats() {
    const { question, stats } = this.props;
    if (!stats || !stats[question.uuid]) return <Loader></Loader>;
    const stat = stats[question.uuid];
    return (
      <div className="question-stats">
        {question.answers.length === 0 && <span>{' '}<span className="stats-alert"><i className="far fa-exclamation-triangle"></i></span> |</span>}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-stats-nb-sessions-${question.uuid}`}>
              {stat.sessions.map(session => session.username).join(', ')}
            </Tooltip>
          }
        >
          <span className="nb-sessions stats-text"><i className="far fa-user"></i> {stat.sessions.length}</span>
        </OverlayTrigger>{' '} |
        {' '}<span className="right-answers stats-text"><i className="far fa-check"></i> {stat.nbCorrectAnswers}</span> |
        {' '}<span className="wrong-answers stats-text"><i className="far fa-times"></i> {stat.nbIncorrectAnswers}</span>
      </div>
    );
  }

  getQuestionAnswers() {
    const { question, stats } = this.props;
    if (!stats || !stats[question.uuid]) return <Loader></Loader>;
    const stat = stats[question.uuid];

    return (
      <div>
        <h5>Bonnes réponses</h5>
        <QuestionAnswerList answerMaps={stat.correctAnswers}></QuestionAnswerList>
        <h5>Mauvaises réponses</h5>
        <QuestionAnswerList answerMaps={stat.incorrectAnswers}></QuestionAnswerList>
      </div>
    );
  }

  render() {
    const { question } = this.props;
    const { open } = this.state;

    return (
      <div className="question-content card-content">
        <div className="question-item">
          <div className="question-order">
            {question.order}
            <div className="question-order-change">
              <i className="far fa-caret-up" onClick={() => this.props.moveQuestion(question.quiz, question.uuid, -1)}></i>
              <i className="far fa-caret-down" onClick={() => this.props.moveQuestion(question.quiz, question.uuid, 1)}></i>
            </div>
          </div>
          <div className="question-name">
            {question.name}
          </div>
          {this.getQuestionStats()}
          <div>
            <Button size="sm" className="br" onClick={this.editQuestion}><i className="far fa-fw fa-edit"></i></Button>{' '}
            <Button size="sm" className="br" onClick={this.toggleQuestion}>{open ? <i className="far fa-fw fa-minus"></i> : <i className="far fa-fw fa-plus"></i>}</Button>
          </div>
        </div>
        <SlideDown className={'my-dropdown-slidedown'}>
          {this.state.open ? (
            <div>
              <div className="question-slidedown">
                <div className="question-description">
                  <ReactMarkdown source={question.description} />
                </div>
                <div className="question-answers">
                  {this.getQuestionAnswers()}
                </div>
              </div>
            </div>
          ) : null}
        </SlideDown>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionItem);
