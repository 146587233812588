import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchHomeQuizzes } from '../../store/quizzes/quizzes.actions';
import { RootState } from '../../store/rootReducer';
import './Home.scss';
import HomeCardQuiz from './HomeCardQuiz/HomeCardQuiz';
import HomeFeaturedQuiz from './HomeFeaturedQuiz/HomeFeaturedQuiz';

const mapStateToProps = ({ quizzes }: RootState) => {
  return {
    quizzes: quizzes.homeQuizzes
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchQuizzes: fetchHomeQuizzes,
}, dispatch);

interface HomeProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {

}

class Home extends Component<HomeProps> {
  componentDidMount() {
    this.props.fetchQuizzes();
  }

  render() {
    const { quizzes } = this.props;

    return (
      <div className="content">
        <Container>
          <div className="mb-3">
            <h4>Quiz du moment</h4>
          </div>
          <Row>
            <Col xs={12}>
              {quizzes.filter(quiz => quiz.featured).map(quiz => (
                <HomeFeaturedQuiz key={quiz.uuid} quiz={quiz} />
              ))}
            </Col>
          </Row>
          <div className="mb-3">
            <h4>Liste des quiz</h4>
          </div>
          <Row>
            {quizzes.filter(quiz => !quiz.featured).map(quiz => (<Col key={quiz.uuid} xs={12} sm={6} md={4}>
              <HomeCardQuiz quiz={quiz} />
            </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
