import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toast } from 'react-toastify';
import { AppThunk } from '..';
import { getApiErrorMessage } from '../../helpers/error-message';
import { Session } from '../../models/session.model';
import QuizService from '../../services/quiz.service';
import SessionService from '../../services/session.service';
import { redirectToQuestion } from '../session/session.actions';
import { setSession } from '../session/session.slice';
import {
  getQuizSuccess, startQuizLoading, startQuizSuccess, getQuizPending
} from './quiz.slice';

export const fetchQuiz = (quizUuid: string): AppThunk => async dispatch => {
  try {
    dispatch(getQuizPending());
    dispatch(showLoading());
    const quiz = await QuizService.getQuiz(quizUuid);
    dispatch(getQuizSuccess(quiz));
  } catch (e) {
    toast.error(getApiErrorMessage(e));
  } finally {
    dispatch(hideLoading());
  }
};

export const startQuiz = (quizUuid: string, username: string): AppThunk => async dispatch => {
  dispatch(startQuizLoading());
  try {
    // Create the session online
    const session = await QuizService.startQuiz(quizUuid, username);
    // Save it in local store
    SessionService.saveSessionToStorage(quizUuid, session);
    dispatch(setSession(session));
    // Redirect to the game
    await dispatch(redirectToQuestion(session));
  } catch (e) {
    toast.error(getApiErrorMessage(e, 'Impossible de charger le quiz. Merci de réessayer plus tard.'));
  } finally {
    dispatch(startQuizSuccess());
  }
};

export const resumeQuiz = (session: Session): AppThunk => async dispatch => {
  dispatch(startQuizLoading());
  try {
    // Redirect to the game
    await dispatch(redirectToQuestion(session));
  } catch (e) {
    toast.error(getApiErrorMessage(e, 'Impossible de charger le quiz. Votre session a peut-être expiré.'));
  } finally {
    dispatch(startQuizSuccess());
  }
};
