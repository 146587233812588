import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { AppThunk } from '..';
import {
  getHomeQuizzesStart, getHomeQuizzesFailure, getHomeQuizzesSuccess
} from './quizzes.slice';
import QuizService from '../../services/quiz.service';

export const fetchHomeQuizzes = (): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch(getHomeQuizzesStart());
    const quizzes = await QuizService.getHomeQuizzes();
    dispatch(getHomeQuizzesSuccess(quizzes));
  } catch (e) {
    dispatch(getHomeQuizzesFailure(e.message));
  } finally {
    dispatch(hideLoading());
  }
};
