import { Form as FormikForm, Formik } from 'formik';
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import swal from 'sweetalert';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { Loader } from '../../../components/Loader/Loader';
import { Quiz } from '../../../models/quiz.model';
import {
  createQuiz, deleteQuiz, deselectQuiz, updateQuiz
} from '../../../store/adminQuizzes/adminQuizzes.actions';
import { RootState } from '../../../store/rootReducer';
import './EditQuiz.scss';

interface EditQuizForm {
  name: string;
  subtitle: string;
  description: string;
  visible: boolean;
  featured: boolean;
  enableStartDate: boolean;
  startDate: Date | null;
}

const QuizSchema = Yup.object().shape({
  name: Yup.string().required('Vous devez entrer le nom du quiz.'),
  subtitle: Yup.string().required('Vous devez entrer un sous-titre.'),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deselectQuiz,
  updateQuiz,
  createQuiz,
  deleteQuiz,
}, dispatch);

const mapStateToProps = ({ adminQuizzes }: RootState) => {
  return {
    isLoading: adminQuizzes.editQuizLoading,
  };
};

export interface EditQuizProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {
  quiz: Quiz;
}

export interface EditQuizState {
  selectedFile?: File,
}

class EditQuiz extends Component<EditQuizProps, EditQuizState> {
  constructor(props: EditQuizProps) {
    super(props);

    this.state = {
      selectedFile: undefined,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.deleteQuiz = this.deleteQuiz.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
  }

  async onSubmit(form: EditQuizForm) {
    const { quiz } = this.props;

    const editedQuiz = {
      ...quiz,
      name: form.name,
      subtitle: form.subtitle,
      description: form.description,
      visible: form.visible,
      featured: form.featured,
      startDate: (form.enableStartDate && form.startDate) ? form.startDate.toISOString() : null,
    };

    console.log(form);
    console.log(editedQuiz);

    if (quiz.uuid) {
      // Edit the quiz
      await this.props.updateQuiz(editedQuiz, this.state.selectedFile);
    } else {
      // Create the quiz
      await this.props.createQuiz(editedQuiz, this.state.selectedFile);
    }
  }

  onImageChange(event: any) {
    this.setState({
      selectedFile: event.target.files[0],
    });
  }

  deleteQuiz() {
    swal({
      dangerMode: true,
      title: 'Supprimer un quiz',
      text: 'Voulez-vous vraiment supprimer ce quiz ?',
      buttons: {
        cancel: {
          text: 'Fermer',
          value: null,
          visible: true,
        },
        confirm: {
          text: 'Supprimer',
          value: true,
          closeModal: false,
        },
      },
    }).then((rep) => {
      if (rep) {
        if (!this.props.quiz) return null;
        swal.close!();
        return this.props.deleteQuiz(this.props.quiz.uuid);
        // .then(() => {
        //   swal.close!();
        // });
      }
      return null;
    });
  }

  render() {
    const { quiz, isLoading } = this.props;

    if (quiz === null) return <div></div>;

    return (
      <div className="EditQuiz p-4">
        <div className="text-right">
          <Button variant="link" className="text-link" onClick={() => { this.props.deselectQuiz(); }}>
            <i className="far fa-times"></i>
          </Button>
        </div>
        <div className="mb-4">
          <h5>{ quiz.uuid ? 'Modifier un quiz' : 'Ajouter un quiz' }</h5>
          {quiz.uuid && <div className="subtitle">UUID: {quiz.uuid}</div>}
        </div>
        <Formik
          initialValues={{
            name: quiz.name,
            subtitle: quiz.subtitle,
            description: quiz.description,
            visible: quiz.visible ?? false,
            featured: quiz.featured ?? false,
            enableStartDate: quiz.startDate != null,
            startDate: quiz.startDate ? new Date(quiz.startDate) : null,
          }}
          validationSchema={QuizSchema}
          onSubmit={this.onSubmit}
        >{({
            values, errors, touched, handleChange, handleBlur, setFieldValue
          }) => (
            <FormikForm>
              <Form.Group>
                <Form.Label>Nom du quiz</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Entrez le nom du quiz"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formNoValidate
                  isInvalid={!!(errors.name && touched.name)}
                ></Form.Control>
                {errors.name && touched.name ? (
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label>Sous-titre</Form.Label>
                <Form.Control
                  type="text"
                  name="subtitle"
                  placeholder="Entrez un sous-titre"
                  value={values.subtitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formNoValidate
                  isInvalid={!!(errors.subtitle && touched.subtitle)}
                ></Form.Control>
                {errors.subtitle && touched.subtitle ? (
                  <Form.Control.Feedback type="invalid">{errors.subtitle}</Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Entrez la description"
                  formNoValidate
                ></Form.Control>
                {errors.description && touched.description ? (
                  <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group controlId="editQuizStartDate">
                <Form.Check
                  type="checkbox"
                  label="Activer la date de début"
                  name="enableStartDate"
                  checked={values.enableStartDate}
                  onChange={handleChange} />
              </Form.Group>
              {values.enableStartDate && <Form.Group>
                <DatePicker
                  className="form-control"
                  name="startDate"
                  showPopperArrow={false}
                  selected={values.startDate}
                  onChange={date => setFieldValue('startDate', date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  dateFormat="dd/MM/yyyy HH:mm"
                  autoComplete="off"
                />
              </Form.Group>}
              <Form.Group controlId="editQuizVisible">
                <Form.Check
                  type="checkbox"
                  label="Rendre le quiz visible à tous"
                  name="visible"
                  checked={values.visible}
                  onChange={handleChange} />
              </Form.Group>
              <Form.Group controlId="editQuizFeatured">
                <Form.Check
                  type="checkbox"
                  label="Mettre en avant sur la page d'accueil"
                  name="featured"
                  checked={values.featured}
                  onChange={handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Image</Form.Label>
                <div>
                  <input type="file" name="file" onChange={this.onImageChange} accept="image/png, image/jpeg"/>
                </div>
              </Form.Group>
              <hr/>
              <div className="mt-4 d-flex align-items-center">
                <Button type="submit" disabled={isLoading}>{ quiz.uuid ? 'Enregistrer' : 'Ajouter' }</Button>
                {quiz.uuid && <Button type="button" onClick={this.deleteQuiz} variant="outline-danger" disabled={isLoading} className="ml-2">Supprimer</Button>}
                {isLoading && <Loader></Loader>}
              </div>
            </FormikForm>
          )}</Formik>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditQuiz);
