import React, { Component } from 'react';
import { RouteProps, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

export interface PageProps extends RouteProps {
  title?: string;
}

export default class Page extends Component<PageProps> {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const { title } = this.props;
    const appName = 'BrainQuiz';
    if (title) {
      document.title = title + ' - ' + appName;
    } else {
      document.title = appName;
    }
  }

  render() {
    return <Route {...this.props} />;
  }
}
