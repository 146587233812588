import axios, { AxiosRequestConfig } from 'axios';
import { Dispatch } from 'redux';
import { API_CALL } from '../middlewares/api.middleware';
import { AuthService } from './auth.service';

export interface ApiAction {
  url: string,
  method: string,
  types?: (string | null)[] | null,
  data?: any,
  onSuccess?: Function,
  onFailure?: Function,
  headers?: {[header: string]: string} | null,
}

export const apiAction = ({
  url = '',
  method = 'GET',
  types = null,
  data = null,
  onSuccess = () => {},
  onFailure = () => {},
  headers = null,
}: ApiAction) => {
  return {
    type: API_CALL,
    payload: {
      url,
      method,
      data,
      types,
      headers,
      onSuccess,
      onFailure
    }
  };
};

export const apiPromise = (
  dispatch: Dispatch,
  apiData: ApiAction
) => {
  return new Promise<any>((resolve, reject) => {
    dispatch(
      apiAction(
        {
          ...apiData,
          onSuccess: resolve,
          onFailure: reject
        }
      )
    );
  });
};


export const fetchApi = async (
  axiosConfig: AxiosRequestConfig,
  retried: boolean = false
) => {
  const token = AuthService.getAuthorizationHeader();
  if (token) {
    if (!axiosConfig.headers) {
      axiosConfig.headers = {};
    }
    axiosConfig.headers.authorization = token;
  }
  try {
    const rep = await axios(axiosConfig);
    return rep.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
