import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { history } from '../helpers/history';
import quizzesReducer from './quizzes/quizzes.slice';
import quizReducer from './quiz/quiz.slice';
import adminQuestionsReducer from './adminQuestions/adminQuestions.slice';
import sessionReducer from './session/session.slice';
import adminQuizzesReducer from './adminQuizzes/adminQuizzes.slice';


const rootReducer = combineReducers({
  quizzes: quizzesReducer,
  quiz: quizReducer,
  adminQuizzes: adminQuizzesReducer,
  adminQuestions: adminQuestionsReducer,
  session: sessionReducer,
  router: connectRouter(history),
  loadingBar: loadingBarReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
