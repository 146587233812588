import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getQuizImageUrl, Quiz } from '../../../models/quiz.model';
import './HomeCardQuiz.scss';

export interface HomeCardQuizProps {
  quiz: Quiz;
}

export default class HomeCardQuiz extends Component<HomeCardQuizProps> {
  render() {
    const { quiz } = this.props;
    return (
      <Link to={'/quiz/' + quiz.uuid} className='text-link'>
        <div className="card-quiz" style={{ backgroundImage: 'url(' + getQuizImageUrl(quiz) + ')' }}>
          <div className="card-quiz-content">
            <div className="quiz-info">
              <div className="quiz-name">
                {quiz.name}
              </div>
              <div className="quiz-description mt-1">
                {quiz.subtitle}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
