import { FieldArray, Form as FormikForm, Formik } from 'formik';
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { Loader } from '../../../components/Loader/Loader';
import { Question } from '../../../models/question.model';
import {
  createQuestion, deselectQuestion, updateQuestion, deleteQuestion
} from '../../../store/adminQuestions/adminQuestions.actions';
import { RootState } from '../../../store/rootReducer';

interface EditQuestionForm {
  name: string;
  description: string;
  answers: string[];
}

const QuestionSchema = Yup.object().shape({
  name: Yup.string().required('Vous devez entrer le nom de la question.'),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deselectQuestion,
  updateQuestion,
  createQuestion,
  deleteQuestion,
}, dispatch);

const mapStateToProps = ({ adminQuestions }: RootState) => {
  return {
    isLoading: adminQuestions.editQuestionLoading
  };
};

export interface EditQuestionProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {
  question: Question;
}

class EditQuestion extends Component<EditQuestionProps> {
  constructor(props: EditQuestionProps) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
  }

  onSubmit(form: EditQuestionForm) {
    console.log('submit');
    const { question } = this.props;

    const editedQuestion = {
      ...question,
      name: form.name,
      description: form.description,
      answers: form.answers,
    };
    console.log(form);
    console.log(form.answers);

    if (question.uuid) {
      // Edit the question
      this.props.updateQuestion(question.quiz, editedQuestion);
    } else {
      // Create the question
      this.props.createQuestion(question.quiz, editedQuestion);
    }
  }

  deleteQuestion() {
    swal({
      dangerMode: true,
      title: 'Supprimer une question',
      text: 'Voulez-vous vraiment supprimer cette question ?',
      buttons: {
        cancel: {
          text: 'Fermer',
          value: null,
          visible: true,
        },
        confirm: {
          text: 'Supprimer',
          value: true,
          closeModal: false,
        },
      },
    }).then((rep) => {
      if (rep) {
        if (!this.props.question) return null;
        swal.close!();
        return this.props.deleteQuestion(this.props.question.quiz, this.props.question.uuid);
        // .then(() => {
        //   swal.close!();
        // });
      }
      return null;
    });
  }

  render() {
    const { question, isLoading } = this.props;

    if (question === null) return <div></div>;

    return (
      <div className="p-4">
        <div className="text-right">
          <Button variant="link" className="text-link" onClick={() => { this.props.deselectQuestion(); }}>
            <i className="far fa-times"></i>
          </Button>
        </div>
        <div className="mb-4">
          <h5>{ question.uuid ? 'Modifier une question' : 'Ajouter une question' }</h5>
        </div>
        <Formik
          initialValues={{
            name: question.name,
            description: question.description,
            answers: question.answers,
          }}
          validationSchema={QuestionSchema}
          onSubmit={this.onSubmit}
        >{({
            values, errors, touched, handleChange, handleBlur
          }) => (
            <FormikForm>
              <Form.Group>
                <Form.Label>Nom de la question</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Entrez le nom de la question"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formNoValidate
                  isInvalid={!!(errors.name && touched.name)}
                ></Form.Control>
                {errors.name && touched.name ? (
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Entrez la description"
                  formNoValidate
                ></Form.Control>
                {errors.description && touched.description ? (
                  <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Label>Réponses</Form.Label>
              <FieldArray
                name='answers'>
                {arrayHelpers => (
                  <div>
                    {values.answers.map((answer, index) => (
                      <div key={index} className="mb-1 d-flex align-items-center">
                        <div className="flex-grow-1">
                          <Form.Control
                            name={'answers.' + index}
                            value={answer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Entrez une réponse">
                          </Form.Control>
                        </div>
                        <div>
                          <Button size="sm" onClick={() => arrayHelpers.remove(index)} className="ml-2 br">-</Button>
                        </div>
                      </div>
                    ))}
                    <Button size="sm" variant="secondary" onClick={() => arrayHelpers.push('')} className="mt-2 br">Ajouter une réponse</Button>
                  </div>
                )}
              </FieldArray>
              <hr/>
              <div className="mt-4 d-flex align-items-center">
                <Button type="submit" disabled={isLoading}>{ question.uuid ? 'Enregistrer' : 'Ajouter' }</Button>
                {question.uuid && <Button type="button" onClick={this.deleteQuestion} variant="outline-danger" disabled={isLoading} className="ml-2">Supprimer</Button>}
                {isLoading && <Loader></Loader>}
              </div>
            </FormikForm>
          )}</Formik>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);
