import React, { Component, PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchQuiz } from '../../store/quiz/quiz.actions';
import { RootState } from '../../store/rootReducer';
import './QuizContainer.scss';
import { Quiz, getQuizImageUrl } from '../../models/quiz.model';
import { Loader } from '../Loader/Loader';

const mapStateToProps = ({ quiz }: RootState) => {
  return {
    quiz: quiz.quiz
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchQuiz
}, dispatch);

interface QuizProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {
  quizUuid: string;
  builder: (quiz: Quiz) => React.ReactNode;
}

class QuizContainer extends Component<PropsWithChildren<QuizProps>> {
  componentDidMount() {
    if (!this.props.quiz || this.props.quiz.uuid !== this.props.quizUuid) {
      this.props.fetchQuiz(this.props.quizUuid);
    }
  }

  render() {
    const { quiz, builder } = this.props;

    if (!quiz) {
      return <div className="d-flex w-100 justify-content-center mt-4">
        <Loader/>
      </div>;
    }

    return (
      <div>
        <div className="quiz-header" style={{ backgroundImage: 'url(' + getQuizImageUrl(quiz) + ')' }}>
          <div className="quiz-header-gradient">
            <div className="quiz-header-content">
              <Container>
                <Row>
                  <Col xs={12}>
                    <div className="quiz-title">
                      <h2>{quiz.name}</h2>
                      <div className="quiz-by-user">Par Samuel</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="quiz-header-bottom"></div>
          </div>
        </div>
        {builder(quiz)}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizContainer);
