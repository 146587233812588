import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Question, QuizStats } from '../../models/question.model';

export interface AdminQuestionsState {
  questions: Question[];
  selectedQuestion: Question | null;
  editQuestionLoading: boolean;
  quizStats: QuizStats | null;
}

const initialState: AdminQuestionsState = {
  questions: [],
  selectedQuestion: null,
  editQuestionLoading: false,
  quizStats: null,
};

const adminQuestionsSlice = createSlice({
  name: 'adminQuestions',
  initialState,
  reducers: {
    setSelectedQuestion: (state, action: PayloadAction<Question | null>) => {
      state.selectedQuestion = action.payload;
    },
    getQuestionsSuccess: (state, action: PayloadAction<Question[]>) => {
      state.questions = action.payload;
    },
    getQuizStatsSuccess: (state, action: PayloadAction<QuizStats | null>) => {
      state.quizStats = action.payload;
    },
    editQuestionLoading: (state) => {
      state.editQuestionLoading = true;
    },
    editQuestionSuccess: (state) => {
      state.editQuestionLoading = false;
    },
    editQuestionFailure: (state, action: PayloadAction<string>) => {
      state.editQuestionLoading = false;
    },
    getQuestionSuccess: (state, action: PayloadAction<Question>) => {
      const index = state.questions.findIndex(question => question.uuid === action.payload.uuid);
      if (index !== -1) {
        state.questions[index] = action.payload;
      } else {
        state.questions.push(action.payload);
      }
    },
    deleteQuestionSuccess: (state, action: PayloadAction<string>) => {
      const index = state.questions.findIndex(question => question.uuid === action.payload);
      if (index !== -1) {
        state.questions.splice(index, 1);
      }
    }
  }
});

export const {
  setSelectedQuestion,
  getQuestionsSuccess,
  getQuestionSuccess,
  editQuestionLoading,
  editQuestionSuccess,
  editQuestionFailure,
  deleteQuestionSuccess,
  getQuizStatsSuccess,
} = adminQuestionsSlice.actions;

export default adminQuestionsSlice.reducer;
