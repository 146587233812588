import React, { Component } from 'react';
import { QuizFeatures } from '../../../models/quiz.model';
import './QuizFeature.scss';

const features: {[feature: string]:
{ name: string, description: string, icon: string }} = {
  [QuizFeatures.Questions]: {
    name: 'Questions',
    description: 'Répondez aux questions posées pour avancer dans le quiz.',
    icon: 'fas fa-question-square',
  },
  [QuizFeatures.Speed]: {
    name: 'Rapidité',
    description: 'Soyez le premier à répondre aux questions.',
    icon: 'fas fa-clock',
  }
};

export interface QuizFeatureProps {
  feature: QuizFeatures;
}

export default class QuizFeature extends Component<QuizFeatureProps> {
  render() {
    const feature = features[this.props.feature];
    if (!feature) return null;

    return (
      <div className="quiz-feature card-content d-flex align-items-center">
        <div className="ml-2 mr-4">
          <i className={ feature.icon }></i>
        </div>
        <div className="flex-grow-1">
          <h5>{ feature.name }</h5>
          <div>{ feature.description }</div>
        </div>
      </div>
    );
  }
}
