import React, { Component } from 'react';
import {
  Button, Col, Container, Row
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import Countdown from '../../components/Countdown/Countdown';
import QuizContainer from '../../components/QuizContainer/QuizContainer';
import { fetchQuiz } from '../../store/quiz/quiz.actions';
import { RootState } from '../../store/rootReducer';
import './Quiz.scss';
import QuizFeature from './QuizFeature/QuizFeature';

const mapStateToProps = ({ quiz }: RootState) => {
  return {
    quiz: quiz.quiz
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchQuiz
}, dispatch);

interface QuizRouteParams {
  quizUuid: string;
}

interface QuizProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  RouteComponentProps<QuizRouteParams> {
}

interface QuizState {
}

class Quiz extends Component<QuizProps, QuizState> {
  componentDidMount() {
  }

  render() {
    const { quizUuid } = this.props.match.params;

    return (
      <QuizContainer quizUuid={quizUuid} builder={quiz => (
        <Container>
          <Row className="justify-content-center">
            <Col md={8} xs={12}>
              <div className="quiz-details">
                <h4>Détails</h4>
                <div className="quiz-description">
                  <ReactMarkdown source={quiz.description} />
                </div>
              </div>
            </Col>
            <Col className="mt-4" md={8} xs={12}>
              {quiz.features.map(feature => <QuizFeature key={feature} feature={feature}></QuizFeature>)}
            </Col>
          </Row>
          <Row className="justify-content-center row-countdown">
            <Col style={{ maxWidth: '700px' }}>
              <Countdown
                startDate={quiz.startDate}
                countdownEndContent={<div className="text-center col-button">
                  <Link to={'/quiz/' + quizUuid + '/start'} className='text-link'>
                    <Button size="lg" className="start-button rounded">Rejoindre le quiz</Button>
                  </Link>
                </div>}
                countdownContent={<div className="countdown-legend">
                  Le quiz démarre dans
                </div>}
              >
              </Countdown>
            </Col>
          </Row>
        </Container>
      )}>
      </QuizContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
