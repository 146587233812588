import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Quiz } from '../../models/quiz.model';

export interface QuizState {
  quiz: Quiz | null;
  startQuizLoading: boolean;
}

const initialState: QuizState = {
  quiz: null,
  startQuizLoading: false,
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    getQuizPending: (state) => {
      state.quiz = null;
    },
    getQuizSuccess: (state, action: PayloadAction<Quiz>) => {
      state.quiz = action.payload;
    },
    startQuizLoading: (state) => {
      state.startQuizLoading = true;
    },
    startQuizSuccess: (state) => {
      state.startQuizLoading = false;
    },
  }
});

export const {
  getQuizPending,
  getQuizSuccess,
  startQuizLoading,
  startQuizSuccess,
} = quizSlice.actions;

export default quizSlice.reducer;
