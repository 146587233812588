import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getQuizImageUrl, Quiz } from '../../../models/quiz.model';
import './HomeFeaturedQuiz.scss';

export interface HomeFeaturedQuizProps {
  quiz: Quiz;
}

export default class HomeFeaturedQuiz extends Component<HomeFeaturedQuizProps> {
  render() {
    const { quiz } = this.props;
    return (
      <Link to={'/quiz/' + quiz.uuid} className='text-link'>
        <div className="featured-quiz" style={{ backgroundImage: 'url(' + getQuizImageUrl(quiz) + ')' }}>
          <div className="featured-quiz-content">
            <div className="quiz-info">
              <div className="quiz-name">
                {quiz.name}
              </div>
              <div className="quiz-description mt-1">
                {quiz.subtitle}
              </div>
              <div className="quiz-button mt-4">
                <Button size="lg" className="pl-4 pr-4">Rejoindre</Button>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
