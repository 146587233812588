import Config from '../constants/config';

export interface Quiz {
  uuid: string;
  name: string;
  subtitle: string;
  description: string;
  visible: boolean;
  featured: boolean;
  startDate: string | null;
  features: QuizFeatures[];
  deletedAt: string | null;
  endPages: EndPage[];
  imageName: string | null;
}

export const getQuizImageUrl = (quiz: Quiz) => {
  if (quiz.imageName) {
    return Config.apiUrl + '/quiz/image/' + quiz.imageName;
  }
  return '/assets/images/quiz.jpg';
};

export interface EndPage {
  from: number;
  to: number;
  description: string;
}

export enum QuizFeatures {
  Questions = 'questions',
  Speed = 'speed',
}
