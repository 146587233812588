import { Form as FormikForm, Formik } from 'formik';
import React, { Component } from 'react';
import {
  Button, Col, Container, Form, Row
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import io from 'socket.io-client';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Loader } from '../../components/Loader/Loader';
import Config from '../../constants/config';
import { RootState } from '../../store/rootReducer';
import {
  checkAnswer, fetchSessionQuestion, loadGame, redirectToQuestion
} from '../../store/session/session.actions';
import './MainQuiz.scss';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  loadGame,
  fetchSessionQuestion,
  redirectToQuestion,
  checkAnswer,
}, dispatch);

const mapStateToProps = ({ session }: RootState) => {
  return {
    session: session.session,
    question: session.question,
    loading: session.questionLoading,
    answerLoading: session.answerLoading,
    lastAttempt: session.lastAttempt,
    nextAllowedTry: session.nextAllowedTry,
  };
};

interface MainQuizRouteParams {
  questionUuid: string;
}

export interface MainQuizProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  RouteComponentProps<MainQuizRouteParams> {

}

export interface MainQuizState {
  buttonProgress: number;
  answerCorrect: boolean;
}

interface MainQuizForm {
  answer: string;
}

const AnswerSchema = Yup.object().shape({
  answer: Yup.string().required('Vous devez entrer une réponse.'),
});

class MainQuiz extends Component<MainQuizProps, MainQuizState> {
  formikRef: any;

  buttonAnimationFrame: number | null = null;

  socket?: SocketIOClient.Socket;

  constructor(props: MainQuizProps) {
    super(props);

    this.state = {
      buttonProgress: 0,
      answerCorrect: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    this.props.loadGame();
    this.loadQuestion();

    // Socket.io
    const url = new URL(Config.apiUrl);
    let { pathname } = url;
    if (!pathname.endsWith('/')) pathname += '/';
    this.socket = io(url.host, { path: pathname + 'socket.io' });
    // this.socket = io(Config.apiUrl);
    this.socket.on('question_updated', (questionUuid: string) => {
      const currentQuestionUuid = this.props.match.params.questionUuid;
      if (questionUuid === currentQuestionUuid) {
        // Toast alert
        toast.info('Question mise à jour !', { autoClose: false });
        // Sound alert
        const audio = new Audio('/assets/sounds/alert.mp3');
        audio.play();
        // Load the question
        this.props.fetchSessionQuestion(currentQuestionUuid);
      }
    });
  }

  componentWillUnmount() {
    if (this.buttonAnimationFrame) {
      cancelAnimationFrame(this.buttonAnimationFrame);
    }

    if (this.socket) {
      this.socket.disconnect();
    }
  }


  componentDidUpdate(prevProps: MainQuizProps) {
    const { questionUuid } = this.props.match.params;

    if (prevProps.match.params.questionUuid !== questionUuid) {
      this.loadQuestion();
    }
  }

  async loadQuestion() {
    const { questionUuid } = this.props.match.params;
    // Question changed
    this.setState({ answerCorrect: false });
    // Reset answer input
    if (this.formikRef) {
      this.formikRef.resetForm();
    }
    // Load the question
    this.props.fetchSessionQuestion(questionUuid);
  }

  async onSubmit(form: MainQuizForm, setErrors: (err: any) => void) {
    if (!this.props.question) return;
    const isCorrect = await this.props.checkAnswer(this.props.question.uuid, form.answer);
    if (isCorrect) {
      this.onQuestionSuccess();
    } else if (isCorrect === false) {
      this.onQuestionFailure(setErrors);
    }
  }

  onQuestionSuccess() {
    // Correct answer
    this.setState({ answerCorrect: true });
    setTimeout(() => {
      // Go to next question
      this.props.redirectToQuestion(this.props.session!);
    }, Config.timeBeforeNextQuestion * 1000);
  }

  onQuestionFailure(setErrors: (err: any) => void) {
    // Display error
    setErrors({ answer: 'La réponse est incorrecte.' });
    // Set button cooldown
    const cooldownTime = this.props.nextAllowedTry - this.props.lastAttempt;
    const buttonAnimation = () => {
      const remainingTime = this.props.nextAllowedTry - Date.now();
      if (remainingTime > 0) {
        this.setState({ buttonProgress: (remainingTime / cooldownTime) * 100 });
        this.buttonAnimationFrame = requestAnimationFrame(buttonAnimation);
      } else {
        this.setState({ buttonProgress: 0 });
        this.buttonAnimationFrame = null;
      }
    };
    this.buttonAnimationFrame = requestAnimationFrame(buttonAnimation);
  }

  getButtonStyle(): React.CSSProperties {
    const { buttonProgress } = this.state;

    if (buttonProgress <= 0) return {};
    return {
      background: 'linear-gradient(to right, #3497fb ' + buttonProgress + '%, #147ee9 ' + buttonProgress + '%)',
    };
  }

  render() {
    const { loading, question, answerLoading } = this.props;
    const { answerCorrect, buttonProgress } = this.state;

    if (loading && !question) {
      return (
        <Loader></Loader>
      );
    }

    if (!question) {
      return <div></div>;
    }

    return (
      <div className="content main-quiz">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} xs={12}>
              <div className="question-header">
                <div className="question-number">
                  Question {question.order}
                </div>
              </div>
              <div className="question-name">
                {question.name}
              </div>
              <div className="question-description">
                <ReactMarkdown source={question.description} />
              </div>
              <Formik
                innerRef={(instance) => { this.formikRef = instance; }}
                initialValues={{
                  answer: '',
                }}
                validateOnMount={true}
                validationSchema={AnswerSchema}
                onSubmit={(form, { setErrors }) => this.onSubmit(form, setErrors)}
              >{({
                  values, errors, touched, handleChange, handleBlur,
                }) => (
                  <FormikForm>
                    <Form.Group className="answer-form-group">
                      <Form.Control
                        className="quiz-input"
                        type="text"
                        name="answer"
                        autoComplete="off"
                        value={values.answer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Entrez votre réponse"
                        isInvalid={!answerCorrect && !!(errors.answer && touched.answer)}
                        isValid={answerCorrect}
                        formNoValidate />
                      {!answerCorrect && errors.answer && touched.answer ? (
                        <Form.Control.Feedback type="invalid">{errors.answer}</Form.Control.Feedback>
                      ) : null}
                      {answerCorrect && (
                        <Form.Control.Feedback type="valid">
                        Bonne réponse ! Passage à la question suivante...
                        </Form.Control.Feedback>)}
                    </Form.Group>
                    <div className="mt-4 d-flex justify-content-center align-items-center">
                      <div>
                        <Button
                          size="lg"
                          type="submit"
                          className="submit-button rounded"
                          disabled={!!errors.answer || buttonProgress > 0 || answerCorrect}
                          style={this.getButtonStyle()}>Envoyer</Button>
                      </div>
                      {answerLoading && <div className="ml-2">
                        <Loader></Loader>
                      </div>}
                    </div>
                  </FormikForm>
                )}</Formik>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainQuiz);
