import { FieldArray, Form as FormikForm, Formik } from 'formik';
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { EndPage, Quiz } from '../../../models/quiz.model';
import { deselectQuiz, updateQuiz } from '../../../store/adminQuizzes/adminQuizzes.actions';
import { RootState } from '../../../store/rootReducer';

interface EditEndPagesForm {
  endPages: EndPage[];
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  deselectQuiz,
  updateQuiz,
}, dispatch);

const mapStateToProps = ({ adminQuizzes }: RootState) => {
  return {
    isLoading: adminQuizzes.editQuizLoading,
  };
};

export interface EditEndPagesProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {
  quiz: Quiz;
}

class EditEndPages extends Component<EditEndPagesProps> {
  constructor(props: EditEndPagesProps) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(form: EditEndPagesForm) {
    const { quiz } = this.props;

    const editedQuiz = {
      ...quiz,
      endPages: form.endPages.map(endPage => ({
        from: endPage.from,
        to: endPage.to,
        description: endPage.description,
      })),
    };

    this.props.updateQuiz(editedQuiz);
  }

  render() {
    const { quiz, isLoading } = this.props;

    if (quiz === null) return <div></div>;

    return (
      <div className="EditEndPages p-4">
        <div className="text-right">
          <Button variant="link" className="text-link" onClick={() => { this.props.deselectQuiz(); }}>
            <i className="far fa-times"></i>
          </Button>
        </div>
        <div className="mb-4">
          <h5>Modifier les pages de fin</h5>
        </div>
        <Formik
          initialValues={{
            endPages: quiz.endPages,
          }}
          onSubmit={this.onSubmit}
        >{({
            values, errors, touched, handleChange, handleBlur, setFieldValue
          }) => (
            <FormikForm>
              <FieldArray
                name='endPages'>
                {arrayHelpers => (
                  <div>
                    {values.endPages.map((endPage, index) => (
                      <div key={index} className="mb-1 d-flex align-items-center">
                        <div className="flex-grow-1 row">
                          <div className="col-6">
                            <input
                              className="form-control"
                              type="number"
                              name={'endPages.' + index + '.from'}
                              value={endPage.from}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Début" />
                          </div>
                          <div className="col-6">
                            <input
                              className="form-control"
                              type="number"
                              name={'endPages.' + index + '.to'}
                              value={endPage.to}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Fin" />
                          </div>
                          <div className="col-12 mt-2">
                            <Form.Group>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                type="text"
                                name={'endPages.' + index + '.description'}
                                value={endPage.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Entrez la description"
                                formNoValidate
                              ></Form.Control>
                            </Form.Group>
                          </div>
                        </div>
                        <div>
                          <Button size="sm" onClick={() => arrayHelpers.remove(index)} className="ml-2 br">-</Button>
                        </div>
                      </div>
                    ))}
                    <Button size="sm" variant="secondary" onClick={() => arrayHelpers.push('')} className="mt-2 br">Ajouter une page</Button>
                  </div>
                )}
              </FieldArray>
              <hr/>
              <div className="mt-4 d-flex align-items-center">
                <Button type="submit" disabled={isLoading}>Enregistrer</Button>
              </div>
            </FormikForm>
          )}</Formik>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEndPages);
