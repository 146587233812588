import { LocalStoreService } from './local-store.service';
import { LOCAL_STORE_SESSIONS, LOCAL_STORE_CURRENT_SESSION } from '../constants/store';
import { Session } from '../models/session.model';
import { fetchApi } from './api.service';
import Config from '../constants/config';

export default class SessionService {
  static loadSessionFromStorage(quizUuid: string): Session | null {
    const sessions = LocalStoreService.getItem(LOCAL_STORE_SESSIONS, {});
    if (sessions[quizUuid]) {
      // Load session
      return sessions[quizUuid];
    }
    return null;
  }

  static saveSessionToStorage(quizUuid: string, session: Session) {
    const sessions = LocalStoreService.getItem(LOCAL_STORE_SESSIONS, {});
    sessions[quizUuid] = session;
    LocalStoreService.setItem(LOCAL_STORE_SESSIONS, sessions);
  }

  static getLastSessionFromStorage(): Session | null {
    return LocalStoreService.getItem(LOCAL_STORE_CURRENT_SESSION, null);
  }

  static saveLastSessionToStorage(session: Session) {
    LocalStoreService.setItem(LOCAL_STORE_CURRENT_SESSION, session);
  }

  static getSession(sessionUuid: string) {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/session',
      headers: {
        'X-Session-UUID': sessionUuid
      }
    });
  }

  static getSessionQuestion(sessionUuid: string, questionUuid: string) {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/session/question/' + questionUuid,
      headers: {
        'X-Session-UUID': sessionUuid
      }
    });
  }

  static getEndPage(sessionUuid: string) {
    return fetchApi({
      method: 'GET',
      url: Config.apiUrl + '/session/end',
      headers: {
        'X-Session-UUID': sessionUuid
      }
    });
  }

  static checkAnswer(sessionUuid: string, questionUuid: string, answer: string) {
    return fetchApi({
      method: 'POST',
      url: Config.apiUrl + '/session/question/' + questionUuid + '/answer',
      headers: {
        'X-Session-UUID': sessionUuid,
      },
      data: {
        answer
      }
    });
  }
}
