export class LocalStoreService {
  static ls = window.localStorage;

  static setItem(key: string, value: any) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  static getItem(key: string, defaultValue: any = null) {
    const value = this.ls.getItem(key);
    try {
      if (value) {
        return JSON.parse(value);
      }
      return defaultValue;
    } catch (e) {
      return defaultValue;
    }
  }

  static clear() {
    this.ls.clear();
  }
}
