export const formatDuration = (secs: number) => {
  secs = Math.round(secs);
  let hours: any = Math.floor(secs / 3600);
  let minutes: any = Math.floor((secs - (hours * 3600)) / 60);
  let seconds: any = secs - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = '0' + hours; }
  if (minutes < 10) { minutes = '0' + minutes; }
  if (seconds < 10) { seconds = '0' + seconds; }
  return hours + ':' + minutes + ':' + seconds;
};
