import React, { Component } from 'react';
import './Countdown.scss';
import moment from 'moment';

export interface CountdownProps {
  startDate: string | null;
  countdownEndContent?: React.ReactNode;
  countdownContent?: React.ReactNode;
}

export interface CountdownState {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  showCountdown: boolean;
}

export default class Countdown extends Component<CountdownProps, CountdownState> {
  private interval: NodeJS.Timeout | null = null;

  constructor(props: CountdownProps) {
    super(props);
    this.state = this.getStateFromProps();
  }

  getStateFromProps(): CountdownState {
    let duration = moment.duration(0);
    if (this.props.startDate) {
      duration = moment.duration(moment(this.props.startDate).diff(moment()));

      if (duration.asMilliseconds() <= 0) {
        duration = moment.duration(0);
      }
    }

    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
      showCountdown: duration.asMilliseconds() > 0,
    };
  }

  componentDidMount() {
    this.setCountdownInterval();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  componentDidUpdate(prevProps: CountdownProps) {
    if (prevProps.startDate !== this.props.startDate) {
      this.setState(this.getStateFromProps());
      this.clearCountdownInterval();
      this.setCountdownInterval();
    }
  }

  setCountdownInterval() {
    if (this.state.showCountdown) {
      this.interval = setInterval(() => {
        this.setState(this.getStateFromProps());
        if (!this.state.showCountdown) {
          this.clearCountdownInterval();
        }
      }, 500);
    }
  }

  clearCountdownInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const {
      days, hours, minutes, seconds, showCountdown
    } = this.state;

    if (!showCountdown) {
      return this.props.countdownEndContent ?? <div></div>;
    }

    return (
      <div className="countdown-content">
        {this.props.countdownContent ?? ''}
        <div className="countdown-timer">
          <div className="countdown-item">
            <div className="countdown-digit">{Math.floor(days / 10)}</div>
            <div className="countdown-digit">{days % 10}</div>
          </div>
          <div className="countdown-sep">:</div>
          <div className="countdown-item">
            <div className="countdown-digit">{Math.floor(hours / 10)}</div>
            <div className="countdown-digit">{hours % 10}</div>
          </div>
          <div className="countdown-sep">:</div>
          <div className="countdown-item">
            <div className="countdown-digit">{Math.floor(minutes / 10)}</div>
            <div className="countdown-digit">{minutes % 10}</div>
          </div>
          <div className="countdown-sep">:</div>
          <div className="countdown-item">
            <div className="countdown-digit">{Math.floor(seconds / 10)}</div>
            <div className="countdown-digit">{seconds % 10}</div>
          </div>
        </div>
        <div className="countdown-label">
          <div className="countdown-label-item">jour{days > 1 ? 's' : ''}</div>
          <div className="countdown-label-item">heure{hours > 1 ? 's' : ''}</div>
          <div className="countdown-label-item">minute{minutes > 1 ? 's' : ''}</div>
          <div className="countdown-label-item">seconde{seconds > 1 ? 's' : ''}</div>
        </div>
      </div>
    );
  }
}
