import React, { Component } from 'react';
import {
  Button, Col, Container, Row
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import {
  fetchQuizzes, selectQuiz, deselectQuiz, setQuizViewAction
} from '../../store/adminQuizzes/adminQuizzes.actions';
import { RootState } from '../../store/rootReducer';
import './AdminQuizzes.scss';
import SlidingPanel, { PanelType } from '../../components/SlidingPanel/SlidingPanel';
import EditQuiz from './EditQuiz/EditQuiz';
import { Quiz } from '../../models/quiz.model';
import { AdminEditQuizView } from '../../store/adminQuizzes/adminQuizzes.slice';
import EditEndPages from './EditEndPages/EditEndPages';

const mapStateToProps = ({ adminQuizzes }: RootState) => {
  return {
    quizzes: adminQuizzes.quizzes,
    selectedQuiz: adminQuizzes.selectedQuiz,
    quizView: adminQuizzes.quizView,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchQuizzes,
  selectQuiz,
  deselectQuiz,
  setQuizViewAction,
}, dispatch);

export interface AdminQuizzesProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {
}

class AdminQuizzes extends Component<AdminQuizzesProps> {
  componentDidMount() {
    this.props.fetchQuizzes();
  }

  addQuiz() {
    this.props.selectQuiz({
      uuid: '',
      name: '',
      subtitle: '',
      description: '',
      startDate: null,
      visible: true,
      featured: true,
      features: [],
      endPages: [],
      deletedAt: null,
      imageName: null,
    });
  }

  editQuiz(quiz: Quiz) {
    this.props.setQuizViewAction(AdminEditQuizView.QuizView);
    this.props.selectQuiz(quiz);
  }

  editQuizEndPages(quiz: Quiz) {
    this.props.setQuizViewAction(AdminEditQuizView.EndPagesView);
    this.props.selectQuiz(quiz);
  }

  render() {
    const { quizzes, selectedQuiz } = this.props;

    return (
      <div className="content">
        <SlidingPanel
          type={PanelType.Right}
          isOpen={selectedQuiz !== null}
          size={50}
          backdropClicked={() => { this.props.deselectQuiz(); }}
        >
          <div>
            {this.props.quizView === AdminEditQuizView.QuizView && <EditQuiz quiz={selectedQuiz!} />}
            {this.props.quizView === AdminEditQuizView.EndPagesView && <EditEndPages quiz={selectedQuiz!} />}
          </div>
        </SlidingPanel>
        <Container>
          <Row>
            <Col className="d-flex align-items-center">
              <h3 className="flex-grow-1">Mes quiz</h3>
              <div>
                {' '}<Button onClick={() => this.addQuiz()}><i className="far fa-plus"></i> Ajouter un quiz</Button>
              </div>
            </Col>
            {quizzes.map(quiz => (
              <Col key={quiz.uuid} xs={12}>
                <div className="quiz card-content mt-4">
                  <div className="quiz-info">
                    <strong className="quiz-name">{quiz.name}</strong><br/>
                    {quiz.description}
                  </div>
                  <div className="quiz-actions">
                    <Link to={'/admin/quiz/' + quiz.uuid + '/questions'} className="text-link">
                      <Button variant="secondary">
                        <i className="far fa-fw fa-question"></i>
                      </Button>
                    </Link>{' '}
                    <Button variant="secondary" onClick={() => this.editQuizEndPages(quiz)}>
                      <i className="far fa-fw fa-award"></i>
                    </Button>{' '}
                    <Button onClick={() => this.editQuiz(quiz)}>
                      <i className="far fa-fw fa-pencil"></i>
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminQuizzes);
