import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Quiz } from '../../models/quiz.model';

export interface AdminQuizzesState {
  quizzes: Quiz[];
  selectedQuiz: Quiz | null;
  quizView: AdminEditQuizView;
  editQuizLoading: boolean;
}

export enum AdminEditQuizView {
  QuizView,
  EndPagesView,
}

const initialState: AdminQuizzesState = {
  quizzes: [],
  selectedQuiz: null,
  quizView: AdminEditQuizView.QuizView,
  editQuizLoading: false,
};

const adminQuizzes = createSlice({
  name: 'adminQuizzes',
  initialState,
  reducers: {
    getQuizzesSuccess: (state, action: PayloadAction<Quiz[]>) => {
      state.quizzes = action.payload;
    },
    getAdminQuizSuccess: (state, action: PayloadAction<Quiz>) => {
      const index = state.quizzes.findIndex(q => q.uuid === action.payload.uuid);
      if (index !== -1) {
        state.quizzes[index] = action.payload;
      } else {
        state.quizzes.push(action.payload);
      }
    },
    setSelectedQuiz: (state, action: PayloadAction<Quiz | null>) => {
      state.selectedQuiz = action.payload;
    },
    setQuizView: (state, action: PayloadAction<AdminEditQuizView>) => {
      state.quizView = action.payload;
    },
    editQuizLoading: (state) => {
      state.editQuizLoading = true;
    },
    editQuizSuccess: (state) => {
      state.editQuizLoading = false;
    },
    editQuizFailure: (state) => {
      state.editQuizLoading = false;
    },
    deleteQuizSuccess: (state, action: PayloadAction<string>) => {
      const index = state.quizzes.findIndex(q => q.uuid === action.payload);
      if (index !== -1) {
        state.quizzes.splice(index, 1);
      }
    }
  }
});

export const {
  getQuizzesSuccess,
  setSelectedQuiz,
  setQuizView,
  editQuizLoading,
  editQuizSuccess,
  editQuizFailure,
  deleteQuizSuccess,
  getAdminQuizSuccess,
} = adminQuizzes.actions;

const adminQuizzesReducer = adminQuizzes.reducer;
export default adminQuizzesReducer;
