import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Quiz } from '../../models/quiz.model';

export interface QuizzesState {
  homeQuizzes: Quiz[];
  isLoading: boolean;
}

const initialState: QuizzesState = {
  homeQuizzes: [],
  isLoading: false,
};

const startLoading = (state: QuizzesState) => {
  state.isLoading = true;
};

const loadingFailed = (state: QuizzesState, action: PayloadAction<string>) => {
  state.isLoading = false;
};

const quizzesSlice = createSlice({
  name: 'quizzes',
  initialState,
  reducers: {
    getHomeQuizzesStart: startLoading,
    getHomeQuizzesSuccess: (state, action: PayloadAction<Quiz[]>) => {
      state.isLoading = false;
      state.homeQuizzes = action.payload;
    },
    getHomeQuizzesFailure: loadingFailed,
  }
});

export const {
  getHomeQuizzesStart,
  getHomeQuizzesSuccess,
  getHomeQuizzesFailure,
} = quizzesSlice.actions;

export default quizzesSlice.reducer;
