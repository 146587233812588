import moment from 'moment';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Loader } from '../../components/Loader/Loader';
import { formatDuration } from '../../helpers/format-duration';
import { RootState } from '../../store/rootReducer';
import { fetchEndPage, loadGame } from '../../store/session/session.actions';
import './EndQuiz.scss';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  loadGame,
  fetchEndPage,
}, dispatch);

const mapStateToProps = ({ session }: RootState) => {
  return {
    loading: session.endPageLoading,
    endPage: session.endPage,
    session: session.session,
  };
};

export interface EndQuizProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps> {

}

class EndQuiz extends Component<EndQuizProps> {
  componentDidMount() {
    this.props.loadGame();
    this.loadEndPage();
  }

  loadEndPage() {
    this.props.fetchEndPage();
  }

  getEndPage() {
    const { endPage, session } = this.props;
    if (!endPage || !session) return null;

    return (
      <div className="card-content">
        <div className="rank d-flex align-items-center justify-content-center">
          <i className="far fa-trophy"></i>
          <div className="ml-4">Votre classement :</div>
          <span className="rank-number ml-4">
            {endPage.rank}
            <span className="rank-sup"> {endPage.rank === 1 ? 'er' : 'ème'}</span>
          </span>
        </div>
        <div className="mt-4">
          <strong>Pseudo : </strong>{session.username} <span className="uuid-hidden">UUID: {session.uuid}</span><br/>
          <strong>Temps : </strong>{
            formatDuration(
              moment.duration(
                moment(endPage.lastCheck).diff(endPage.createdAt)
              ).asSeconds()
            )}
        </div>
        {endPage.endDescription && <div className="end-page mt-4">
          <ReactMarkdown source={endPage.endDescription} />
        </div>}
      </div>
    );
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="EndQuiz content">
        <div className="container">
          <Row className="justify-content-center">
            <Col xs={12} md={10} className="text-center">
              <h4 className="mb-4">Fin du quiz</h4>
              <div className="mb-4">Félicitations, vous avez terminé le quiz !</div>

            </Col>
            <Col xs={12} md={10}>
              {loading ? <Loader /> : this.getEndPage()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndQuiz);
