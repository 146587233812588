import React, { Component } from 'react';
import {
  Button, Col, Container, Form, Row
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as Yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import { Loader } from '../../components/Loader/Loader';
import QuizContainer from '../../components/QuizContainer/QuizContainer';
import { fetchQuiz, resumeQuiz, startQuiz } from '../../store/quiz/quiz.actions';
import { RootState } from '../../store/rootReducer';
import { loadSessionFromStorage } from '../../store/session/session.actions';
import './QuizStart.scss';

const mapStateToProps = ({ quiz, session }: RootState) => {
  return {
    quiz: quiz.quiz,
    loading: quiz.startQuizLoading,
    session: session.session,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchQuiz,
  startQuiz,
  resumeQuiz,
  loadSessionFromStorage,
}, dispatch);

interface QuizStartRouteParams {
  quizUuid: string;
}

interface QuizStartProps
  extends ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  RouteComponentProps<QuizStartRouteParams> {
}

interface QuizStartState {
  /** True if it should display the username input
   * even if there is a loaded session */
  createNewSession: boolean;
}

interface QuizStartForm {
  username: string;
}

const QuizStartSchema = Yup.object().shape({
  username: Yup
    .string()
    .trim()
    .required('Vous devez entrer votre pseudo.')
    .matches(/^[a-zA-Z0-9 _-À-ÖØ-öø-ÿÉÈ]+$/, 'Votre pseudo ne doit pas contenir de caractères spéciaux.'),
});

class QuizStart extends Component<QuizStartProps, QuizStartState> {
  constructor(props: QuizStartProps) {
    super(props);

    this.state = {
      createNewSession: false,
    };

    this.startQuiz = this.startQuiz.bind(this);
    this.resumeQuiz = this.resumeQuiz.bind(this);
  }

  componentDidMount() {
    const { quizUuid } = this.props.match.params;

    // Load a session if one exists
    this.props.loadSessionFromStorage(quizUuid);
  }

  startQuiz(form: QuizStartForm) {
    if (form.username.length === 0) return;
    if (!this.props.quiz) return;

    this.props.startQuiz(this.props.quiz.uuid, form.username);
  }

  resumeQuiz() {
    if (this.props.loading) return;
    if (!this.props.session) return;
    this.props.resumeQuiz(this.props.session);
  }

  render() {
    const { quizUuid } = this.props.match.params;
    const { session, loading } = this.props;
    const { createNewSession } = this.state;

    return (
      <QuizContainer quizUuid={quizUuid} builder={quiz => (
        <Container className="quiz-start">
          {createNewSession || !session
            ? <Row className="justify-content-center">
              <Col md={8} xs={12}>
                <Formik
                  initialValues={{
                    username: '',
                  }}
                  validateOnMount={true}
                  validationSchema={QuizStartSchema}
                  onSubmit={this.startQuiz}
                >{({
                    values, errors, touched, handleChange, handleBlur, isValid,
                  }) => (
                    <FormikForm>
                      <div className="quiz-details">
                        <h4>Rejoindre le quiz</h4>
                        <div>
                        Entrez votre pseudo pour commencer le quiz.
                        </div>
                      </div>
                      <Form.Group className="mt-4">
                        <Form.Control
                          className="quiz-input"
                          type="text"
                          name="username"
                          autoComplete="off"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Entrez votre pseudo"
                          isInvalid={!!(errors.username && touched.username)}
                          formNoValidate />
                        {errors.username && touched.username ? (
                          <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                        ) : null}
                      </Form.Group>
                      <div className="text-center col-button d-flex align-items-center justify-content-center">
                        <div>
                          <Button
                            size="lg"
                            type="submit"
                            className="start-button rounded"
                            disabled={!isValid || loading}>Rejoindre le quiz</Button>
                        </div>
                        {loading && <div className="ml-4">
                          <Loader></Loader>
                        </div>}
                      </div>
                    </FormikForm>
                  )}
                </Formik>
              </Col>
            </Row> : <Row className="justify-content-center">
              <Col md={8} xs={12}>
                <div className="quiz-details">
                  <h4>Rejoindre le quiz</h4>
                  <div>
                  Reprenez le quiz là où vous en étiez, ou commencez une nouvelle session.
                  </div>
                </div>
                <div className="resume-quiz br mt-4 d-flex align-items-center" onClick={this.resumeQuiz}>
                  <div className="flex-grow-1">
                      Reprendre en tant que {session.username}
                  </div>
                  {loading && <Loader></Loader>}
                  <div>
                    <i className="far fa-chevron-right"></i>
                  </div>
                </div>
                <div className="mt-2 text-center">
                  <Button variant="link" className="text-link" onClick={() => this.setState({ createNewSession: true })}>ou commencer une nouvelle session</Button>
                </div>
              </Col>
            </Row>}
        </Container>
      )}>
      </QuizContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizStart);
