import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Session, SessionEndPage } from '../../models/session.model';
import { SessionQuestion } from '../../models/session-question.model';

export interface SessionState {
  session: Session | null;
  question: SessionQuestion | null;
  questionLoading: boolean;
  answerLoading: boolean;
  lastAttempt: number;
  nextAllowedTry: number;
  endPageLoading: boolean;
  endPage?: SessionEndPage;
}

const initialState: SessionState = {
  session: null,
  question: null,
  questionLoading: false,
  answerLoading: false,
  lastAttempt: 0,
  nextAllowedTry: Date.now(),
  endPageLoading: false,
  endPage: undefined,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session | null>) => {
      state.session = action.payload;
    },
    setSessionQuestion: (state, action: PayloadAction<SessionQuestion>) => {
      state.question = action.payload;
      state.questionLoading = false;
    },
    setSessionQuestionLoading: (state) => {
      state.questionLoading = true;
    },
    setSessionQuestionFailed: (state) => {
      state.questionLoading = false;
    },
    setCheckAnswerLoading: (state) => {
      state.answerLoading = true;
    },
    setCheckAnswerSuccess: (state) => {
      state.answerLoading = false;
    },
    setLastAttempt: (state, action: PayloadAction<number>) => {
      state.lastAttempt = action.payload;
    },
    setNextAllowedTry: (state, action: PayloadAction<number>) => {
      state.nextAllowedTry = action.payload;
    },
    setEndPagePending: (state) => {
      state.endPageLoading = true;
    },
    setEndPageFailed: (state) => {
      state.endPageLoading = false;
    },
    setEndPageSuccess: (state, action: PayloadAction<SessionEndPage>) => {
      state.endPageLoading = false;
      state.endPage = action.payload;
    },
  }
});

export const {
  setSession,
  setSessionQuestion,
  setSessionQuestionLoading,
  setSessionQuestionFailed,
  setCheckAnswerLoading,
  setCheckAnswerSuccess,
  setLastAttempt,
  setNextAllowedTry,
  setEndPagePending,
  setEndPageFailed,
  setEndPageSuccess,
} = sessionSlice.actions;

export default sessionSlice.reducer;
